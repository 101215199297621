import React, { Component } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import css from "./modal.module.scss";

function updateValue(key) {
  return function(e) {
    this.setState({
      value: {
        ...this.state.value,
        [key]: e.target.value
      }
    });
  };
}

function deleteValue(key) {
  return function() {
    this.state.value[key] = this.initValue[key];
    this.setState({ value: this.state.value });
  };
}

class Modal extends Component {
  initValue = {};

  constructor(props) {
    super(props);

    this.initValue = Object.assign({}, this.props.value);
    this.state = {
      margin: (window.innerHeight - 500) / 2,
      categories: [],
      value: this.initValue
    };

    this.calacMargin = this.calacMargin.bind(this);
  }
  render() {
    return (
      <div className={css.modal} onClick={this.props.onBackgroundClick}>
        <div className={css.content} onClick={e => e.stopPropagation()}>
          <div className={css.header}>添加收藏</div>
          <div className={css.body}>
            <div className={css.item}>
              <div className={css.label}>地址</div>
              <div className={css.input}>
                <input
                  type="text"
                  onChange={updateValue("url").bind(this)}
                  defaultValue={this.state.value.url}
                />
              </div>
            </div>
            <div className={css.item}>
              <div className={css.label}>自定义标题</div>
              <div className={css.input}>
                <Switch
                  onChange={value => {
                    this.setState({ manuTitle: value });
                    value || deleteValue("title").call(this);
                  }}
                  value={this.state.value.manuTitle}
                  checkedChildren={"是"}
                  unCheckedChildren={"否"}
                />
              </div>
            </div>
            {this.state.manuTitle && (
              <div className={css.item}>
                <div className={css.label}>标题</div>
                <div className={css.input}>
                  <input
                    type="text"
                    onChange={updateValue("title").bind(this)}
                    defaultValue={this.state.value.title}
                  />
                </div>
              </div>
            )}
            <div className={css.item}>
              <div className={css.label}>新建分类</div>
              <div className={css.input}>
                <Switch
                  name="newCategory"
                  onChange={value => {
                    this.setState({ newCategory: value });
                    value || deleteValue("newCategory").call(this);
                  }}
                  checkedChildren={"是"}
                  unCheckedChildren={"否"}
                />
              </div>
            </div>
            <div className={css.item}>
              <div className={css.label}>分类</div>
              <div className={css.input}>
                {this.state.newCategory ? (
                  <input
                    type="text"
                    onChange={updateValue("newCategory").bind(this)}
                  />
                ) : (
                  <select
                    onChange={updateValue("category").bind(this)}
                    defaultValue={this.state.value.category}
                  >
                    <option />
                    {this.props.categories.map(category => (
                      <option value={category.id} key={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className={css.footer}>
            <div
              className={[css.cancel, css.btn].join(" ")}
              onClick={() =>
                this.props.onCancel && this.props.onCancel(this.state.value)
              }
            >
              {this.props.canceling ? (
                <img
                  className={css.loading}
                  src={require("../static/loading.ico")}
                  alt=""
                />
              ) : (
                this.props.cancelText
              )}
            </div>
            <div
              className={[css.confirm, css.btn].join(" ")}
              onClick={() => {
                let value = this.state.value;
                if (typeof value.category === "string") {
                  value.category = parseInt(value.category);
                }
                this.props.onConfirm && this.props.onConfirm(value);
              }}
            >
              {this.props.confirming ? (
                <img
                  className={css.loading}
                  src={require("../static/loading.ico")}
                  alt=""
                />
              ) : (
                this.props.confirmText
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  changeManulTitle(value) {
    this.setState({ manuTitle: value });
  }
  calacMargin() {
    this.setState({ margin: (window.innerHeight - 500) / 2 });
  }
  async componentWillMount() {
    window.addEventListener("resize", this.calacMargin);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.calacMargin);
  }
}

export default Modal;
