import React, { Component } from "react";
import css from "./siteCard.module.scss";

class SiteCard extends Component {
  startPress = 0;
  longpressTrigged = false;
  constructor(props) {
    super(props);
    this.state = { icon: require("../static/placeholder.ico") };

    let img = new Image();
    img.src = props.favicon;
    img.onload = () => this.setState({ icon: props.favicon });
  }
  render() {
    return (
      <div className={css.site}>
        <div
          className={css["icon-canvas"]}
          onClick={this.props.onClick}
          onContextMenu={e => {
            e.preventDefault();
            this.props.onContextMenu && this.props.onContextMenu();
          }}
        >
          <div
            className={css["icon-content"]}
            style={{ backgroundImage: `url("${this.state.icon}")` }}
          />
        </div>
        <h3>{this.props.title}</h3>
      </div>
    );
  }
}

export default SiteCard;
