import React, { Component } from "react";
import SiteCard from "./siteCard";
import css from "./category.module.scss";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }
  render() {
    return (
      <div>
        <div
          className={css.spliter}
          onClick={() => {
            this.setState({ show: !this.state.show });
          }}
        >
          {this.props.value.name}
        </div>
        {this.state.show &&
          this.props.value.items.map(d => {
            return (
              <SiteCard
                title={d.title}
                favicon={d.favicon}
                key={d.id}
                onClick={() => (window.location.href = d.url)}
                onContextMenu={() =>
                  this.props.onContextMenu && this.props.onContextMenu(d)
                }
              />
            );
          })}
      </div>
    );
  }
}

export default Category;
