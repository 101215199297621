import React, { Component } from "react";
import "./App.css";
import SiteCard from "./component/siteCard";
import Modal from "./component/modal";
import Category from "./component/category";
import categoryCss from "./component/category.module.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: [],
      showDefault: true,
      list: [],
      margin: (document.body.offsetWidth % 150) / 2,
      showAdd: false,
      showEdit: false,
      isAdding: false,
      isEditing: false,
      isDeling: false,
    };

    this.calacMargin = this.calacMargin.bind(this);
    this.loadFavorite = this.loadFavorite.bind(this);
    this.addFavorite = this.addFavorite.bind(this);
    this.editFavorite = this.editFavorite.bind(this);
    this.delFavorite = this.delFavorite.bind(this);

    this.loadFavorite();
  }
  render() {
    return (
      <div id="app" style={{ margin: `0 ${this.state.margin}px` }}>
        <div>
          <div className={categoryCss.spliter}>未分类</div>
          <SiteCard
            favicon={require("./static/add.ico")}
            onClick={() => this.setState({ showAdd: true })}
          />
          {this.state.default.map((d) => {
            return (
              <SiteCard
                title={d.title}
                favicon={d.favicon}
                key={d.id}
                onClick={() => (window.location.href = d.url)}
                onContextMenu={() =>
                  this.setState({ showEdit: true, editValue: d })
                }
              />
            );
          })}
        </div>
        {this.state.list.map((category) => (
          <Category
            key={category.id}
            value={category}
            onContextMenu={(value) =>
              this.setState({ showEdit: true, editValue: value })
            }
          />
        ))}
        {this.state.showAdd && (
          <Modal
            onBackgroundClick={() => this.setState({ showAdd: false })}
            onCancel={() => this.setState({ showAdd: false })}
            onConfirm={this.addFavorite}
            categories={this.state.list}
            confirming={this.state.isAdding}
            confirmText="确定"
            cancelText="取消"
          />
        )}

        {this.state.showEdit && (
          <Modal
            onBackgroundClick={() => this.setState({ showEdit: false })}
            onCancel={this.delFavorite}
            onConfirm={this.editFavorite}
            value={this.state.editValue}
            categories={this.state.list}
            confirmText="修改"
            confirming={this.state.isEditing}
            cancelText="删除"
            canceling={this.state.isDeling}
          />
        )}
      </div>
    );
  }
  getLoginUrl() {
    const host = window.location.host;
    const hostParts = host.split(".");
    if (hostParts.length === 3) hostParts[0] = "login";
    else hostParts.unshift("login");

    return `${window.location.protocol}//${hostParts.join(
      "."
    )}?redirect=${encodeURIComponent(window.location.href)}`;
  }
  async loadFavorite() {
    let [category, favorite] = await Promise.all([
      fetch("/api/category").then((res) => {
        if (res.status === 403) window.location.href = this.getLoginUrl();
        else return res.json();
      }),
      fetch("/api/favorite").then((res) => {
        if (res.status === 403) window.location.href = this.getLoginUrl();
        else return res.json();
      }),
    ]);

    this.setState(
      favorite.reduce(
        (result, fav) => {
          let c = result.list.find((v) => v.id === fav.category);
          if (c) {
            c.items.push(fav);
          } else {
            result.default.push(fav);
          }
          return result;
        },
        {
          default: [],
          list: category.map((c) =>
            Object.assign(c, { items: [], show: false })
          ),
        }
      )
    );
  }
  async addFavorite(value) {
    if (!this.state.isAdding) {
      this.setState({ isAdding: true });
      await fetch("/api/favorite", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(value),
      });

      await this.loadFavorite();
      this.setState({ showAdd: false, isAdding: true });
    }
  }
  async editFavorite(value) {
    if (!this.state.isEditing) {
      this.setState({ isEditing: true });
      await fetch("/api/favorite/" + value.id, {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(value),
      });
      await this.loadFavorite();
      this.setState({ showEdit: false, isEditing: false });
    }
  }
  async delFavorite({ id }) {
    if (!this.state.isDeling) {
      this.setState({ isDeling: true });
      this.state.communicating = 3;
      await fetch("/api/favorite/" + id, {
        method: "DELETE",
      });
      await this.loadFavorite();
      this.setState({ showEdit: false, isDeling: false });
    }
  }
  calacMargin() {
    this.setState({ margin: (document.body.offsetWidth % 150) / 2 });
  }
  componentDidMount() {
    window.addEventListener("resize", this.calacMargin);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.calacMargin);
  }
}

export default App;
